import React from "react";
import IJobDetails from "../../interfaces/IJobDetails";
import JobListNew from "../JobListNew";
import JobSectionWrapper from "../JobSectionWrapper";
import { Heading16, Typo14 } from "../Typography";
import { JobsOtherHeading, JobsOtherWrapper } from "./style";

interface Props {
  jobs: IJobDetails[];
}

const JobOther: React.FC<Props> = (props) => {
  const { jobs } = props;

  if (jobs.length === 0) return null;

  return (
    <JobSectionWrapper>
      <JobsOtherWrapper>
        <JobsOtherHeading>
          <Heading16 color="#658AC8" fontWeight="700" as="h2">
            Similar roles
          </Heading16>
        </JobsOtherHeading>
        <JobListNew jobs={jobs} hideInfo={true} section={true} />
      </JobsOtherWrapper>
    </JobSectionWrapper>
  );
};

export default JobOther;
