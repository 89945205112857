import styled, { css } from 'styled-components';
import { media } from '../../common/MediaQueries';

interface ISecondary {
  secondary?: boolean;
}

export const JobContentWrapper = styled.div<ISecondary>`
  ${({ secondary }) => (secondary ? '' : 'padding-top: 20px; ')}

  @media ${media.desktop} {
    ${({ secondary }) =>
      secondary
        ? css`
            display: flex;
            flex-wrap: wrap;
          `
        : css`
            padding-top: 4px;
          `};
  }
`;

interface IJobContentBorder extends ISecondary {
  skipBorder?: boolean;
  desktopSkipBorder?: boolean;
}

export const JobContentBorder = styled.div<IJobContentBorder>`
  padding-bottom: 7px;

  ${({ skipBorder }) =>
    skipBorder ? 'border: none;' : 'border-bottom: 1px solid rgba(101, 138, 200, 0.2 );'}

  @media ${media.desktop} {
    padding-bottom: 0;

    ${({ secondary }) =>
      secondary
        ? css`
            width: 0;
            flex-grow: 1;
            margin-right: 38px;
            flex-shrink: 0;
          `
        : ''}

    ${({ desktopSkipBorder }) =>
      desktopSkipBorder ? 'border: none;' : 'border-bottom: 1px solid rgba(101, 138, 200, 0.2 );'}
  }
`;

export const JobContentItem = styled.div``;

interface IJobContentItemHeader {
  drawer: boolean;
  secondary?: boolean;
  expandableOnDesktop?: boolean;
}

export const JobContentItemHeader = styled.div<IJobContentItemHeader>`
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 13px;

  ${({ drawer }) => (drawer ? 'cursor: pointer;' : '')};

  @media ${media.tablet} {
    ${({ expandableOnDesktop }) =>
      expandableOnDesktop ? 'padding-bottom: 20px;' : 'padding-bottom: 16px;'}
  }
`;

export const JobContentImageWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  background: #f0f5ff;
  border-radius: 6px;

  @media ${media.tablet} {
    margin-top: 3px;
  }
`;

export const JobContentImage = styled.div`
  width: 11px;
  height: 11px;

  @media ${media.tablet} {
    width: 14px;
    height: 14px;
  }
`;

export const JobContentHeading = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  margin-left: 8px;
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    letter-spacing: -0.02em;
    min-height: none;
    margin-left: 10px;
  }
`;

interface IJobContentArrowWrapper {
  drawer: boolean;
}

export const JobContentArrowWrapper = styled.div<IJobContentArrowWrapper>`
  ${({ drawer }) => (drawer ? 'display: flex;' : 'display: none;')};
  justify-content: center;
  align-items: center;
  flex-shtink: 0;
  margin-left: auto;
`;

interface IJobContentArrow {
  showing: boolean;
  keepOnDesktop?: boolean;
}

export const JobContentArrow = styled.div<IJobContentArrow>`
  ${({ showing }) => (!showing ? 'transform: rotate( 180deg );' : '')};
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 200ms ease-out;

  @media ${media.tablet} {
    ${({ keepOnDesktop }) => !keepOnDesktop && 'display: none;'}
  }
`;

export const JobContentHTML = styled.div`
  margin-bottom: 13px;
  color: #0b264c;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
  }
`;
