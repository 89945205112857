import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "../../hooks/windowSize";
import {
  ExpadndableHeading,
  ExpadndableWrapper,
  ExpandableMaxHeight,
} from "./styles";

interface Props {
  renderHeading: Function;
  interactionCallback?: Function;
  allwaysOpen?: boolean;
  expandableOnDesktop?: boolean;
}

const Expandable: React.FC<Props> = (props) => {
  const {
    children,
    renderHeading,
    allwaysOpen,
    interactionCallback,
    expandableOnDesktop,
  } = props;

  const [show, setShow] = useState(false);

  const { width: windowWidth } = useWindowSize();

  const ref = useRef(null);

  useEffect(() => {
    if (allwaysOpen) return;

    if (windowWidth > 1024 && !expandableOnDesktop) return;

    if (!ref.current) return;

    const el = ref.current as HTMLDivElement;

    if (show) {
      el.style.maxHeight = `${el.scrollHeight}px`;

      return;
    }

    el.style.maxHeight = "0";
  }, [show, windowWidth]);

  return (
    <ExpadndableWrapper>
      <ExpadndableHeading
        expandableOnDesktop={expandableOnDesktop}
        onClick={() => {
          if (allwaysOpen) return;

          interactionCallback && interactionCallback();

          setShow(!show);
        }}
      >
        {renderHeading(show)}
      </ExpadndableHeading>
      <ExpandableMaxHeight
        ref={ref}
        allwaysOpen={allwaysOpen}
        expandableOnDesktop={expandableOnDesktop}
      >
        {children}
      </ExpandableMaxHeight>
    </ExpadndableWrapper>
  );
};

export default Expandable;
