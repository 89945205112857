import React from "react";
import aEvent from "../../common/aEvent";
import ICandidateTestemonial from "../../interfaces/ICandidateTestemonials";
import HTMLContent from "../HTMLContent";
import JobContentApplySmall from "../JobContentApplySmall";
import JobSectionWrapper from "../JobSectionWrapper";
import SimpleSlider from "../SimpleSlider";
import StrapiImage from "../StrapiImage";
import { Typo16, Typo30 } from "../Typography";
import {
  JobCandidateApply,
  JobCandidateImage,
  JobCandidateItem,
  JobCandidateName,
  JobCandidatePadding,
  JobCandidateQuote,
  JobCandidatesBg,
  JobCandidatesWrapper,
} from "./styles";

interface Props {
  items: ICandidateTestemonial[];
  applyUrl: string;
}

const JobCandidates: React.FC<Props> = (props) => {
  const { items, applyUrl } = props;

  if (items.length === 0) return null;

  const first = items.slice(0, 6);

  return (
    <JobCandidatesBg>
      <JobCandidatesWrapper>
        <SimpleSlider
          padding={21}
          paddingDesktop={0}
          dotsMargin={21}
          dotsMarginDesktop={48}
          alternativeDots={true}
          keepDots={true}
          items={first}
          dots={true}
          formatItems={true}
          centerOnDesktopBelowItemCount={4}
          interactionCallback={() => {
            aEvent({
              ec: "Slider Events",
              ea: "slider-interaction",
              el: "candidate-testemonials",
              ni: false,
            });
          }}
          renderItem={(item: ICandidateTestemonial) => {
            const { image, name, quote } = item;

            return (
              <JobCandidatePadding key={`${name}${quote}`}>
                <JobCandidateItem>
                  <JobCandidateImage>
                    {image && <StrapiImage image={image} />}
                  </JobCandidateImage>
                  <JobCandidateName>
                    <Typo30 color="#658AC8" textAlign="center" fontWeight="600">
                      {name}
                    </Typo30>
                  </JobCandidateName>
                  <JobCandidateQuote>
                    <HTMLContent html={quote} />
                  </JobCandidateQuote>
                </JobCandidateItem>
              </JobCandidatePadding>
            );
          }}
        />
        <JobCandidateApply>
          <JobContentApplySmall url={applyUrl} />
        </JobCandidateApply>
      </JobCandidatesWrapper>
    </JobCandidatesBg>
  );
};

export default JobCandidates;
