import React, { memo, useMemo } from "react";
import DrawerArrow from "../../assets/svg/DrawerArrow";
import Expandable from "../Expandable";
import {
  CompanyInfoArrow,
  CompanyInfoArrowWrapper,
  CompanyInfoContent,
  CompanyInfoHeading,
  CompanyInfoMoreWrapper,
  CompanyInfoWrapper,
} from "./styles";
import aEvent from "../../common/aEvent";

interface Props {
  type: string;
  renderHeading: Function;
}

const CompanyInfo: React.FC<Props> = (props) => {
  const { children, type, renderHeading } = props;

  const heading = useMemo(() => renderHeading(), []);

  return (
    <CompanyInfoWrapper>
      <Expandable
        interactionCallback={() => {
          aEvent({
            ec: "Content Events",
            ea: "drawer-open",
            el: type,
            ni: false,
          });
        }}
        renderHeading={(show: boolean) => (
          <CompanyInfoHeading>
            {heading}
            <CompanyInfoMoreWrapper>
              <CompanyInfoArrowWrapper>
                <CompanyInfoArrow show={show}>
                  <DrawerArrow />
                </CompanyInfoArrow>
              </CompanyInfoArrowWrapper>
            </CompanyInfoMoreWrapper>
          </CompanyInfoHeading>
        )}
      >
        <CompanyInfoContent>{children}</CompanyInfoContent>
      </Expandable>
    </CompanyInfoWrapper>
  );
};

export default CompanyInfo;
