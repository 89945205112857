import normalizeCaseStudies from './normalizeCaseStudies';
import normalizeCompanies from './normalizeCompanies';
import normalizeEdges from './normalizeEdges';
import normalizeJobs from './normalizeJobs';

export default function normalizeJobData(data: any, pageContext: any) {
  const {
    job: unformattedJob,
    company: unformattedCompany,
    strapiJobPage,
    faqs: unformattedFaqs,
    otherJobs: unformattedOtherJobs,
  } = data as any;

  const {
    heroImage,
    heroText,
    defaultTimeToHire,
    caseStudiesHeading,
    aboutSubheading,
    aboutHeading,
    aboutText,
    aboutImage,
    aboutQuote,
    aboutDisclaimer,
    theGoodStuffHeading,
    stepsHeading,
    stepsImage,
    stepsImageDesktop,
    clientTestemonials: unformattedClientTestemonials,
    candidateTestemonials: unformattedCandidateTestemonials,
    techFallbackImage,
    cotnentFallbackImage: contentFallbackImage,
    applySteps: unformattedApplySteps,
  } = strapiJobPage;

  const allFaqs = normalizeEdges(unformattedFaqs);

  const job = normalizeJobs([unformattedJob])[0];

  const company = normalizeCompanies([unformattedCompany])[0];

  const { caseStudies: unformattedCaseStudies } = company;

  const caseStudies = normalizeCaseStudies(unformattedCaseStudies);

  const faqs = allFaqs.filter(faq => job.faqs.some((uid: string) => uid === faq.uid));

  const formatted = {
    ...job,
    company: {
      ...company,
      caseStudies,
    },
    faqs,
  };

  const clientTestemonials = (unformattedClientTestemonials || []).filter(
    (item: any) => item.client && item.quote && item.position
  );

  const candidateTestemonials = (unformattedCandidateTestemonials || []).filter(
    (item: any) => item.name && item.image && item.quote
  );

  const normalizedOtherJobs = normalizeEdges(unformattedOtherJobs);

  const otherJobs = normalizeJobs(normalizedOtherJobs);

  const { otherJobIds } = pageContext;

  const orderedOtherJobs = [...otherJobs].sort((a, b) =>
    otherJobIds.indexOf(a.id) < otherJobIds.indexOf(b.id) ? -1 : 1
  );

  const applySteps = (unformattedApplySteps || []).filter((item: any) => item.heading);

  return {
    heroImage,
    heroText,
    defaultTimeToHire,
    caseStudiesHeading,
    aboutSubheading,
    aboutHeading,
    aboutText,
    aboutImage,
    aboutQuote,
    aboutDisclaimer,
    theGoodStuffHeading,
    job: formatted,
    clientTestemonials,
    stepsHeading,
    stepsImage,
    stepsImageDesktop,
    candidateTestemonials,
    otherJobs: orderedOtherJobs,
    techFallbackImage,
    contentFallbackImage,
    applySteps,
  };
}
