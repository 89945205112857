export default async function fetchRetry(
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  retry = 0
): Promise<any> {
  try {
    const res = await fetch(input, init);

    const { status } = res;

    if (status === 429) {
      return { throttled: true };
    }

    if (status < 200 || status >= 300) {
      throw new Error(`Got ${status} response from request to ${JSON.stringify(input)}`);
    }

    const json = await res.json();

    return json;
  } catch (e: any) {
    console.error(e);

    if (retry < 3) return fetchRetry(input, init, retry + 1);

    return { error: true };
  }
}
