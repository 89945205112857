import React from "react";
import IStrapiImage from "../../interfaces/IStrapiImage";
import ApplyNowTooltip from "../ApplyNowTooltip";
import JobSectionWrapper from "../JobSectionWrapper";
import StrapiImage from "../StrapiImage";
import { Heading17, Typo14 } from "../Typography";
import {
  JobStepsImage,
  JobStepsImagePadding,
  JobStepsImageWrapper,
  JobStepsWrappe,
  StepsHover,
  StepsHoverContent,
} from "./style";

interface Props {
  heading: string;
  image: IStrapiImage | null;
  desktopImage: IStrapiImage | null;
}

const hovers = [
  {
    top: "29.5%",
    left: "3.44%",
  },
  {
    top: "29.5%",
    left: "21.04%",
  },
  {
    top: "29.5%",
    left: "38.43%",
  },
  {
    top: "29.5%",
    left: "56.22%",
  },
  {
    top: "29.5%",
    left: "73.9%",
  },
  {
    top: "29.5%",
    left: "91.84%",
  },
];

const JobSteps: React.FC<Props> = (props) => {
  const { heading, image, desktopImage } = props;

  if (!image || !desktopImage) return null;

  return (
    <JobStepsWrappe>
      <JobSectionWrapper>
        {heading && (
          <Heading17
            color="#658AC8"
            textAlign="center"
            fontWeight="600"
            as="h2"
          >
            {heading}
          </Heading17>
        )}
        <JobStepsImageWrapper>
          <JobStepsImagePadding>
            <JobStepsImage>
              <StrapiImage image={[image, desktopImage]} />
            </JobStepsImage>
            {hovers.map((hover, index) => {
              const { top, left } = hover;

              return (
                <StepsHover key={index} top={top} left={left}>
                  <ApplyNowTooltip>
                    <StepsHoverContent />
                  </ApplyNowTooltip>
                </StepsHover>
              );
            })}
          </JobStepsImagePadding>
        </JobStepsImageWrapper>
      </JobSectionWrapper>
    </JobStepsWrappe>
  );
};

export default JobSteps;
