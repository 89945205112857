import React from "react";
import IJobDetails from "../../interfaces/IJobDetails";
import { JobTimeWrapper } from "./styles";
import { Typo12 } from "../Typography";
import TimeBadge from "../TimeBadge";
import formatGMT from "../../common/formatGMT";
import IJob from "../../interfaces/IJob";

interface Props {
  job: IJobDetails;
}

export const JobTime: React.FC<Props> = (props) => {
  const { job } = props;

  const {
    timezoneCategory,
    timezoneGMTMax,
    timezoneGMTMin,
    customLocation,
  } = job;

  const timeFrom = timezoneCategory ? timezoneCategory.fromGMT : timezoneGMTMin;
  const timeTo = timezoneCategory ? timezoneCategory.toGMT : timezoneGMTMax;

  const hasValidTimezone =
    typeof timeFrom !== "undefined" &&
    typeof timeTo !== "undefined" &&
    timeFrom !== null &&
    timeTo !== null;


  if (customLocation) {
    return (
      <JobTimeWrapper>
      <Typo12
        color="#0B264C"
        style={{ marginRight: "14px", fontFamily: "Montserrat, sans-serif" }}
      >
        Candidate Location: <span style={{ fontWeight: 700 }}>{customLocation}</span>
      </Typo12>
    </JobTimeWrapper>
    )
  }

  if (!hasValidTimezone) return null;

  return (
    <JobTimeWrapper>
      <Typo12
        color="#0B264C"
        style={{ marginRight: "14px", fontFamily: "Montserrat, sans-serif" }}
      >
        <TimeWrapperContent
          job={job}
          timeFrom={timeFrom}
          timeTo={timeTo}
        />
      </Typo12>
      <TimeBadge fromGMT={timeFrom} toGMT={timeTo} />
    </JobTimeWrapper>
  );
};

interface TimeWrapperContentProps {
  job: IJob
  timeFrom: number
  timeTo: number
}

function TimeWrapperContent(props: TimeWrapperContentProps) {
  const { job, timeFrom, timeTo } = props;

  const {
    timezoneCategory,
    timezoneFormatted,
  } = job;

  if (timezoneCategory || timezoneFormatted) {
    return <>Recommended timezone: {timezoneCategory ? timezoneCategory.name : timezoneFormatted}</>;
  }

  return <>Recommended timezone: {formatGMT([timeFrom, timeTo])}</>;
}
