import React from "react";
import IStrapiImage from "../../interfaces/IStrapiImage";
import { TypograpyAbout } from "../HTMLContent/style";
import JobContentApplySmall from "../JobContentApplySmall";
import JobSectionWrapper from "../JobSectionWrapper";
import StrapiImage from "../StrapiImage";
import { Heading16, Typo16, Typo17, Typo29 } from "../Typography";
import {
  JobAboutDisclaimer,
  JobAboutHeading,
  JobAboutImage,
  JobAboutQuote,
  JobAboutText,
  JobAboutWrapper,
  JobAboutSubheading,
  JobAboutBorder,
  JobAboutApplyWrapper,
} from "./styles";

interface Props {
  heading: string;
  subheading: string;
  text: string;
  image: IStrapiImage;
  quote: string;
  disclaimer: string;
  applyUrl: string;
  hasBorder: boolean;
}

const JobAbout: React.FC<Props> = (props) => {
  const {
    heading,
    subheading,
    text,
    quote,
    image,
    disclaimer,
    applyUrl,
    hasBorder,
  } = props;

  return (
    <JobAboutWrapper>
      <JobSectionWrapper>
        <JobAboutBorder hasBorder={hasBorder}>
          <JobAboutSubheading>
            <Typo29 color="#0B264C" as="h3">
              {subheading}
            </Typo29>
          </JobAboutSubheading>
          <JobAboutHeading>
            <Heading16 color="#658AC8" as="h2">
              {heading}
            </Heading16>
          </JobAboutHeading>
          <JobAboutText>
            <TypograpyAbout dangerouslySetInnerHTML={{ __html: text }} />
          </JobAboutText>
          <JobAboutQuote>
            <Typo17 color="#0B264C" fontWeight="600" textAlign="center" as="h3">
              {quote}
            </Typo17>
          </JobAboutQuote>
          {image && (
            <JobAboutImage>
              <StrapiImage image={image} />
            </JobAboutImage>
          )}
          <JobAboutDisclaimer>
            <Typo16 color="#0B264C" textAlign="center">
              {disclaimer}
            </Typo16>
          </JobAboutDisclaimer>
          <JobAboutApplyWrapper>
            <JobContentApplySmall url={applyUrl} />
          </JobAboutApplyWrapper>
        </JobAboutBorder>
      </JobSectionWrapper>
    </JobAboutWrapper>
  );
};

export default JobAbout;
