import React from "react";
import Close from "../../assets/svg/Close";
import aEvent from "../../common/aEvent";
import ApplyButton from "../ApplyButton";
import SendEmail from "../SendEmail";
import { Typo14, Typo16, Typo6 } from "../Typography";
import {
  SendPopupCard,
  SendPopupClose,
  SendPopupCloseIcon,
  SendPopupInput,
  SendPopupInputError,
  SendPopupInputWrapper,
  SendPopupMessage,
  SendPopupOverlay,
  SendPopupSubheading,
  SnedPopupSubmit,
} from "./styles";

interface Props {
  setOpen: Function;
  slug: string;
}

export const SendPopup: React.FC<Props> = (props) => {
  const { slug, setOpen } = props;

  return (
    <SendEmail
      url={`${process.env.GATSBY_API_URL}/content-api/sent-roles/`}
      payload={{ slug }}
      onSubmitCallback={() => {
        aEvent({
          ea: "send-role-to-email",
          ec: "Email Events",
          el: slug || "jobs-page",
          ni: false,
        });
      }}
      renderContent={(props: any) => {
        const {
          value,
          state,
          valid,
          displayValid,
          onBlur,
          onFocus,
          onChange,
          onSubmit,
        } = props;

        let message;
        let color;

        if (state.success) {
          message = "Role sent. You can close the popup.";
          color = "#333";
        } else if (state.error) {
          message = "Something went wrong. Please try again.";
          color = "#FF5070";
        } else if (state.throttled) {
          message = "Sorry. You cannot send any new roles for a while.";
          color = "#444";
        }

        return (
          <SendPopupOverlay onClick={() => setOpen(false)}>
            <SendPopupCard
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Typo6 color="#000" fontWeight="600">
                Sending the role to email
              </Typo6>
              <SendPopupSubheading>
                <Typo14>
                  Please enter your email address to receive a full description
                  of the role
                </Typo14>
              </SendPopupSubheading>
              <SendPopupInputWrapper>
                <SendPopupInput
                  error={!displayValid}
                  onChange={(e) => onChange(e.target.value)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  value={value}
                  type="email"
                />
                {!displayValid && (
                  <SendPopupInputError>
                    <Typo16
                      fontWeight="500"
                      color="#FF5070"
                      textAlign="center"
                      lineHeight="17px"
                    >
                      This mail doesn’t exist, please enter the correct data
                    </Typo16>
                  </SendPopupInputError>
                )}
              </SendPopupInputWrapper>
              <SnedPopupSubmit>
                {!state.success && !state.error && !state.throttled ? (
                  <ApplyButton
                    text="Send"
                    disabled={!valid || state.loading}
                    onClick={onSubmit}
                  />
                ) : (
                  <SendPopupMessage>
                    <Typo16 color={color}>{message}</Typo16>
                  </SendPopupMessage>
                )}
              </SnedPopupSubmit>
              <SendPopupClose onClick={() => setOpen(false)}>
                <SendPopupCloseIcon>
                  <Close />
                </SendPopupCloseIcon>
              </SendPopupClose>
            </SendPopupCard>
          </SendPopupOverlay>
        );
      }}
    />
  );
};
