import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";

const Wrapper = styled.div`
  padding: 0 27px 0 27px;

  @media ${media.desktop} {
    padding: 0 116px;
    width: 100%;
    max-width: 1536px;
    margin: 0 auto;
  }
`;

const JobSectionWrapper: React.FC = (props) => {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
};

export default JobSectionWrapper;
