import React from "react";
import IJobDetails from "../../interfaces/IJobDetails";
import IStrapiImage from "../../interfaces/IStrapiImage";
import GradientHero from "../GradientHero";

interface Props {
  job: IJobDetails;
  text: string;
  defaultTimeToHire: string;
  image?: IStrapiImage | null;
}

const GradientHeroTemplated: React.FC<Props> = (props) => {
  const { job, text, defaultTimeToHire, image } = props;

  const { company } = job;

  const { timeToHire } = company;

  const formattedTimeToHire = timeToHire || defaultTimeToHire || "2 weeks";

  const formattedText = text
    .replace("$company", company.name)
    .replace("$time", formattedTimeToHire);

  return <GradientHero text={formattedText} image={image} />;
};

export default GradientHeroTemplated;
