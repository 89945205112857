import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const Typography = styled.div`
  & a {
    font-weight: 700;
    text-decoration: underline;
  }

  & strong {
    font-weight: 600;
  }

  & ul > li {
    padding-left: 1.92em;
    position: relative;
  }

  & ul > li:before {
    background-color: ${({ theme }) => theme.colors.watermelon};

    content: '';
    width: 0.375em;
    height: 0.375em;
    border-radius: 50%;
    left: 0.78em;
    position: absolute;
    top: 0.66em;
    transform: translate(0, -50%);
  }

  & h1 {
    font-size: 2.25em;
    line-height: 1.1111111;
    margin-top: 0;
    margin-bottom: 0.8888889em;
  }

  & h2 {
    font-size: 1.5em;
    line-height: 1.3333333;
    margin-bottom: 1em;
    margin-top: 2em;
  }

  & h3 {
    font-size: 1.25em;
    line-height: 1.6;
    margin-bottom: 0.6em;
    margin-top: 1.6em;
  }

  & h4,
  & h5 {
    font-size: 1.125em;
    line-height: 1.6;
    margin-bottom: 0.6em;
    margin-top: 1.6em;
  }

  & ul {
    margin-bottom: 1.25em;
    margin-top: 1.25em;
  }

  & li {
    margin-bottom: 0.785em;
    margin-top: 0.785em;
  }

  & > ul > li > :first-child {
    margin-top: 1.25em;
  }

  & > ul > li > :last-child {
    margin-bottom: 1.25em;
  }

  & ul ul {
    margin-bottom: 0.75em;
    margin-top: 0.75em;
  }

  & p {
    margin-top: 1.57em;
    margin-bottom: 1.57em;
  }

  && h2 + *,
  && h3 + *,
  && h4 + *,
  && h5 + * {
    margin-top: 0;
  }

  && > :first-child {
    margin-top: 0;
  }

  && > :last-child {
    margin-bottom: 0;
  }
`;

export const TypograpyAbout = styled(Typography)`
  & p {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  @media ${media.desktop} {
    & p {
      margin-top: 1.75em;
      margin-bottom: 1.75em;
    }
  }
`;

export const TypographyCompanyContent = styled(Typography)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-weight: 500;
    margin-top: 1.42em;
    margin-bottom: 0.42em;
    font-size: 1em;
    line-height: 1.21;
    color: #0b264c;
  }

  & p {
    margin-top: 1.42em;
    margin-bottom: 1.42em;
    letter-spacing: -0.01em;
    line-height: 1.375;
  }

  & p + ul {
    margin-top: -0.7em;
  }

  & ul li {
    padding-left: 1.07em;
    letter-spacing: -0.01em;
    line-height: 1.375;
  }

  & ul > li:before {
    left: 0;
  }

  @media ${media.desktop} {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-weight: 500;
      margin-top: 1.5em;
      margin-bottom: 0.3em;
      font-size: 1.25em;
      letter-spacing: -0.002em;
      line-height: 1.6;
    }

    & p {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
    }

    & ul {
      margin-top: 0;
      margin-bottom: 0;
    }

    & p + ul {
      margin-top: -1.5em;
    }

    & ul li {
      margin: 0;
      padding-left: 1.375em;
    }

    & ul li:before {
      left: 0.666em;
      background: currentColor;
      width: 0.125em;
      height: 0.125em;
    }
  }
`;

export const TypographyCompanyDay = styled(Typography)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 1.14em;
    line-height: 1.375;
    font-weight: 500;
    margin-bottom: 1.07em;
    margin-top: 2em;
  }

  & p {
    margin-top: 1.07em;
    margin-bottom: 1.07em;
  }

  & ul {
    margin-top: 1.07em;
    margin-bottom: 1.07em;
  }

  & ul > li {
    margin-top: 1.35em;
    margin-bottom: 1.35em;
  }

  & ul > li:first-child {
    margin-top: 0;
  }

  & ul > li:last-child {
    margin-bottom: 0;
  }

  & ul > li:before {
    left: 0;
  }

  @media ${media.desktop} {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-top: 1.55em;
      margin-bottom: 1.55em;
    }

    & ul > li {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    & p {
      margin-top: 1.25em;
      margin-bottom: 1.25em;
    }
  }
`;
export const TypograpyJobContent = styled(Typography)`
  & li {
    margin-top: 1.125em;
    margin-bottom: 1.125em;
  }

  & li:first-child {
    margin-top: 0;
  }

  & li:last-child {
    margin-bottom: 0;
  }

  @media ${media.desktop} {
    & ul > li {
      padding-left: 1.6875em;
    }

    & ul > li:before {
      left: 0.5em;
      width: 0.25em;
      height: 0.25em;
    }
  }
`;
