import React from "react";
import IJobDetails from "../../interfaces/IJobDetails";
import IStrapiImage from "../../interfaces/IStrapiImage";
import ITechItem from "../../interfaces/ITechItem";
import StrapiImage from "../StrapiImage";
import { Typo15, Typo21, Typo22, Typo25, Typo26 } from "../Typography";
import {
  TechCardHeading,
  TechCardImage,
  TechCardItems,
  TechCardItemsWrapper,
  TechCardName,
  TechCardRow,
  TechCardWrapper,
} from "./style";

interface Props {
  job: IJobDetails;
  margin?: number;
  techFallbackImage: IStrapiImage | null;
}

function TechCardRowCommon({
  item,
  techFallbackImage,
}: {
  item: ITechItem;
  techFallbackImage: IStrapiImage | null;
}) {
  const { image, name, uid } = item;

  return (
    <TechCardRow key={uid}>
      <TechCardImage>
        {(image || techFallbackImage) && (
          <StrapiImage image={image || techFallbackImage} />
        )}
      </TechCardImage>
      <TechCardName>
        <Typo26 color="#000">{name}</Typo26>
      </TechCardName>
    </TechCardRow>
  );
}

const TechCard: React.FC<Props> = (props) => {
  const { job, margin, techFallbackImage } = props;

  const { mustHaveTechItems, niceToHaveTechItems } = job;

  if (mustHaveTechItems.length === 0 && niceToHaveTechItems.length === 0)
    return null;

  return (
    <TechCardWrapper margin={margin}>
      {mustHaveTechItems.length > 0 && (
        <TechCardItemsWrapper>
          <TechCardHeading>
            <Typo21 color="#1B1B1B" fontWeight="600">
              Required stack
            </Typo21>
          </TechCardHeading>
          <TechCardItems>
            {mustHaveTechItems.map((item) => (
              <TechCardRowCommon
                key={item.uid}
                techFallbackImage={techFallbackImage}
                item={item}
              />
            ))}
          </TechCardItems>
        </TechCardItemsWrapper>
      )}
      {niceToHaveTechItems.length > 0 && (
        <TechCardItemsWrapper>
          <TechCardHeading>
            <Typo21 color="#1B1B1B" fontWeight="600">
              Nice to have
            </Typo21>
          </TechCardHeading>
          <TechCardItems>
            {niceToHaveTechItems.map((item) => (
              <TechCardRowCommon
                key={item.uid}
                techFallbackImage={techFallbackImage}
                item={item}
              />
            ))}
          </TechCardItems>
        </TechCardItemsWrapper>
      )}
    </TechCardWrapper>
  );
};

export default TechCard;
