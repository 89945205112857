import styled, { css } from 'styled-components';
import { media } from '../../common/MediaQueries';

export const SendPopupOverlay = styled.div`
  display: none;

  @media ${media.desktop} {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.05);
    z-index: 11;
  }
`;

export const SendPopupCard = styled.div`
  position: relative;
  width: 603px;
  padding: 30px 38px 38px 38px;
  text-align: center;
  background: #ffffff;
  box-shadow: 9px 0px 28px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
`;

export const SendPopupSubheading = styled.div`
  width: 400px;
  margin: 13px auto 0 auto;
  letter-spacing: -0.01em;
  color: #546782;
`;

export const SendPopupInputWrapper = styled.div`
  position: relative;
  margin: 37px auto 0 auto;
  width: 380px;
`;

interface ISendPopupInput {
  error: boolean;
}

export const SendPopupInput = styled.input.attrs({
  placeholder: 'Enter your email here...',
})<ISendPopupInput>`
  display: block;
  width: 100%;
  padding: 14px 25px 15px 25px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  border: none;
  background: #f7f7f7;
  border-radius: 4px;
  font-family: Montserrat;

  ${({ error }) =>
    error
      ? css`
          border: 1px solid #ff5070;
        `
      : css`
          border: 1px solid #f7f7f7;
        `}

  & ::placeholder {
    color: #697a90;
  }
`;

export const SnedPopupSubmit = styled.div`
  width: 229px;
  margin: 42px auto 0 auto;
`;

export const SendPopupClose = styled.button`
  position: absolute;
  width: 46px;
  height: 46px;
  top: 18px;
  right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SendPopupCloseIcon = styled.div`
  width: 16px;
  height: 16px;
`;

export const SendPopupInputError = styled.div`
  position: absolute;
  top: calc(100% + 9px);
  left: 0;
  width: 100%;
`;

export const SendPopupMessage = styled.div`
  height: 52px;
  display: flex;
  justify-content: center;
`;
