export default function normalizeCompanies(unformattedCompanies: any) {
  if (!unformattedCompanies) return [];

  return unformattedCompanies
    .map((company: any) => {
      const {
        theGoodStuff,
        featuredInfo,
        caseStudies,
        finItems,
        teamStats,
        cultureHeading,
        cultureSubheading,
        cultureText,
        secondary,
        links,
      } = company;

      return {
        ...company,
        theGoodStuff: theGoodStuff || [],
        featuredInfo: featuredInfo || [],
        caseStudies: caseStudies || [],
        finItems: finItems || [],
        teamStats: teamStats || [],
        secondary: secondary || [],
        links: links || [],
        cultureHeading: cultureHeading || '',
        cultureSubheading: cultureSubheading || '',
        cultureText: cultureText || '',
      };
    })
    .map((company: any) => {
      const {
        theGoodStuff: unfformattedGoodStuff,
        featuredInfo: unformattedFeaturedInfo,
        finItems: unformattedFinItems,
        teamStats: unformattedTeamStats,
        secondary: unformattedSecondary,
        links: unformattedLinks,
      } = company;

      const theGoodStuff = unfformattedGoodStuff.filter((item: any) => item.heading);

      const featuredInfo = unformattedFeaturedInfo.filter(
        (item: any) => item.bigText && item.smallText
      );

      const finItems = unformattedFinItems.filter((item: any) => item.heading && item.value);

      const teamStats = unformattedTeamStats.filter((item: any) => item.heading && item.value);

      const secondary = unformattedSecondary.filter((item: any) => item.heading && item.content);

      const links = unformattedLinks.filter((item: any) => item.url && item.label);

      return {
        ...company,
        theGoodStuff,
        featuredInfo,
        finItems,
        teamStats,
        secondary,
        links,
      };
    });
}
