import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const CompanyInfoWrapper = styled.div`
  background: #ffffff;
  border: 1px solid rgba(155, 192, 255, 0.5);
  border-radius: 15px;
  overflow: hidden;
  padding: 0 18px;

  @media ${media.tablet} {
    border: 0;
    padding: 0;
    border-radius: 0;
  }
`;

export const CompanyInfoHeading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 9px 0;
  letter-spacing: -0.01em;
  cursor: pointer;
  font-weight: 600;
  lettter-spacing: -0.01em;

  @media ${media.tablet} {
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    pointer-events: none;
  }
`;

export const CompanyInfoMoreWrapper = styled.div`
  margin-left: auto;
  padding-left: 20px;
  flex-shrink: 0;
  padding-right: 6px;

  @media ${media.tablet} {
    display: none;
  }
`;

interface ICompanyInfoArrow {
  show: boolean;
}

export const CompanyInfoArrowWrapper = styled.div`
  padding-right: 6px;
`;

export const CompanyInfoArrow = styled.div<ICompanyInfoArrow>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  transition: transform 200ms ease-out;

  ${({ show }) => (show ? '' : 'transform: rotate( 180deg )')}
`;

export const CompanyInfoContent = styled.div`
  font-size: 14px;
  line-height: 1.21;
  color: rgba(11, 38, 76, 0.7);

  @media ${media.tablet} {
    font-size: 16px;
    line-height: 1.375;
  }
`;
