import React from "react";
import Minus from "../../assets/svg/Minus";
import Plus from "../../assets/svg/Plus";
import aEvent from "../../common/aEvent";
import IFaq from "../../interfaces/IFaq";
import Expandable from "../Expandable";
import HTMLContent from "../HTMLContent";
import JobContentApply from "../JobContentApply";
import JobSectionWrapper from "../JobSectionWrapper";
import { Heading12, Heading16 } from "../Typography";
import {
  JobFaqAnswer,
  JobFaqApply,
  JobFaqHeading,
  JobFaqItem,
  JobFaqItems,
  JobFaqMoreWrapper,
  JobFaqWrapper,
  JobFaqWrapperHeading,
} from "./style";

interface Props {
  faqs: IFaq[];
  applyUrl: string;
}

const JobFaq: React.FC<Props> = (props) => {
  const { faqs, applyUrl } = props;

  if (faqs.length === 0) return null;

  const first = faqs.slice(0, 4);

  return (
    <JobSectionWrapper>
      <JobFaqWrapper>
        <JobFaqWrapperHeading>
          <Heading16 color="#658AC8" fontWeight="700" as="h2">
            FAQ
          </Heading16>
        </JobFaqWrapperHeading>
        <JobFaqItems>
          {first.map((item) => {
            const { uid, question, answer } = item;

            return (
              <JobFaqItem key={uid}>
                <Expandable
                  interactionCallback={() => {
                    aEvent({
                      ec: "Content Events",
                      ea: "drawer-open",
                      el: "faq",
                      ni: false,
                    });

                    aEvent({
                      ec: "Content Events",
                      ea: "faq-drawer-open",
                      el: question,
                      ni: false,
                    });
                  }}
                  renderHeading={(show: boolean) => {
                    return (
                      <JobFaqHeading>
                        <h3>{question}</h3>
                        <JobFaqMoreWrapper>
                          {show ? <Minus /> : <Plus />}
                        </JobFaqMoreWrapper>
                      </JobFaqHeading>
                    );
                  }}
                >
                  <JobFaqAnswer>
                    <HTMLContent html={answer} />
                  </JobFaqAnswer>
                </Expandable>
              </JobFaqItem>
            );
          })}
        </JobFaqItems>
        <JobFaqApply>
          <JobContentApply noBorder={true} applyUrl={applyUrl} />
        </JobFaqApply>
      </JobFaqWrapper>
    </JobSectionWrapper>
  );
};

export default JobFaq;
