import React, { useContext } from "react";
import decorateUrl from "../../common/decorateUrl";
import { SendContext } from "../../contexts/SendContext";
import { UtmContext } from "../../contexts/UtmContext";
import linkedinEvent from "../../lib/linkedin-event";
import ApplyButton from "../ApplyButton";
import { Typo14 } from "../Typography";
import { ApplySmallSendWrapper, ApplySmallWrapper } from "./styles";

interface Props {
  url: string;
}

const JobContentApplySmall: React.FC<Props> = ({ url }) => {
  const { setOpen: setPopupOpen } = useContext(SendContext);

  const { utms } = useContext(UtmContext);

  const decoratedUrl = decorateUrl(url, utms);

  return (
    <ApplySmallWrapper>
      <ApplyButton
        as="a"
        url={decoratedUrl}
        onClick={() => { 
          linkedinEvent('toggl');
        }}
      />
      <ApplySmallSendWrapper>
        <Typo14
          onClick={() => setPopupOpen(true)}
          color="#658AC8"
          fontWeight="600"
        >
          Send Role to Email
        </Typo14>
      </ApplySmallSendWrapper>
    </ApplySmallWrapper>
  );
};

export default JobContentApplySmall;
