import React from "react";
import DrawerArrow from "../../assets/svg/DrawerArrow";
import aEvent from "../../common/aEvent";
import IJobContent from "../../interfaces/IJobContent";
import IStrapiImage from "../../interfaces/IStrapiImage";
import Expandable from "../Expandable";
import { TypograpyJobContent } from "../HTMLContent/style";
import StrapiImage from "../StrapiImage";
import { Typo24 } from "../Typography";
import {
  JobContentItem,
  JobContentWrapper,
  JobContentImageWrapper,
  JobContentHeading,
  JobContentHTML,
  JobContentItemHeader,
  JobContentBorder,
  JobContentImage,
  JobContentArrowWrapper,
  JobContentArrow,
} from "./styles";

interface Props {
  items: IJobContent[];
  secondary?: boolean;
  hasMore: boolean;
  contentFallbackImage: IStrapiImage | null;
}

const JobContent: React.FC<Props> = (props) => {
  const { items, secondary, hasMore, contentFallbackImage } = props;

  if (!items || items.length === 0) return null;

  return (
    <JobContentWrapper secondary={secondary}>
      {items.map((item, index) => {
        return (
          <JobContentDrawer
            key={index}
            drawer={secondary || index > 0}
            item={item}
            secondary={secondary}
            skipBorder={
              (secondary && index === items.length - 1) ||
              (index === items.length - 1 && !hasMore)
            }
            skipBorderDesktop={secondary || index === items.length - 1}
            contentFallbackImage={contentFallbackImage}
          />
        );
      })}
    </JobContentWrapper>
  );
};

interface IJobContentItem {
  drawer: boolean;
  item: IJobContent;
  secondary?: boolean;
  skipBorder?: boolean;
  skipBorderDesktop?: boolean;
  contentFallbackImage: IStrapiImage | null;
}

const JobContentDrawer: React.FC<IJobContentItem> = (props) => {
  const {
    drawer,
    item,
    secondary,
    skipBorder,
    skipBorderDesktop,
    contentFallbackImage,
  } = props;

  const { icon, heading, content } = item;

  const expandableOnDesktop = false && drawer && /bonus/i.test(heading);

  return (
    <JobContentBorder
      skipBorder={skipBorder}
      desktopSkipBorder={skipBorderDesktop}
      secondary={secondary}
    >
      <JobContentItem>
        <Expandable
          allwaysOpen={!drawer}
          expandableOnDesktop={expandableOnDesktop}
          interactionCallback={() => {
            aEvent({
              ec: "Content Events",
              ea: "drawer-open",
              el: "job-info-drawer",
              ni: false,
            });

            aEvent({
              ec: "Content Events",
              ea: "job-info-drawer-open",
              el: heading,
              ni: false,
            });
          }}
          renderHeading={(show: any) => {
            return (
              <JobContentItemHeader
                drawer={drawer}
                secondary={secondary}
                expandableOnDesktop={expandableOnDesktop}
              >
                <JobContentImageWrapper>
                  {(icon || contentFallbackImage) && (
                    <JobContentImage>
                      <StrapiImage image={icon || contentFallbackImage} />
                    </JobContentImage>
                  )}
                </JobContentImageWrapper>
                <JobContentHeading>
                  <Typo24 color="#658AC8" fontWeight="600" as="h2">
                    {heading}
                  </Typo24>
                </JobContentHeading>
                <JobContentArrowWrapper drawer={drawer}>
                  <JobContentArrow
                    showing={show}
                    keepOnDesktop={expandableOnDesktop}
                  >
                    <DrawerArrow />
                  </JobContentArrow>
                </JobContentArrowWrapper>
              </JobContentItemHeader>
            );
          }}
        >
          <JobContentHTML>
            <TypograpyJobContent
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </JobContentHTML>
        </Expandable>
      </JobContentItem>
    </JobContentBorder>
  );
};

export default JobContent;
