import React from "react";
import IJobDetails from "../../interfaces/IJobDetails";
import stringifyHTML from "../../lib/stringify-html";
import SEO from "../Seo";

type Props = {
  job: IJobDetails;
};

const JobSEO: React.FC<Props> = (props) => {
  const { job } = props;

  const {
    name: jobTitle,
    uid: jobUid,
    slug,
    seo,
    company,
    main,
    createdAt,
    countries,
    salaryCurrency,
    expires,
    salaryMin,
    salaryMax,
    salaryAggregation,
  } = job;

  const { title: seoTitle, description, author, image } = seo;

  const { name: companyName, links, logo } = company;

  const imagePath =
    image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src ||
    logo?.localFile?.publicURL ||
    null;

  const companyLogo =
    company.logo?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback
      ?.src;

  const imageWithFallback = imagePath || companyLogo || null;

  const imageUrl = imagePath
    ? `https://${process.env.GATSBY_PUBLIC_DOMAIN}${imageWithFallback}`
    : "";

  const companyUrl =
    links.filter((link) => link.label.toLowerCase().trim() === "website")[0]
      ?.url || "";

  const seoDescriptiom = description 
    ? description 
    : `${main[0]?.heading} | ${stringifyHTML(main[0]?.content || "")}`

  const baseSalary =
    salaryMin && salaryMax
      ? {
          "@type": "MonetaryAmount",
          currency: salaryCurrency?.toUpperCase(),
          value: {
            "@type": "QuantitativeValue",
            minValue: salaryMin,
            maxValue: salaryMax,
            unitText: salaryAggregation?.toUpperCase(),
          },
        }
      : salaryMin || salaryMax
      ? {
          "@type": "MonetaryAmount",
          currency: salaryCurrency?.toUpperCase(),
          value: {
            "@type": "QuantitativeValue",
            value: salaryMin || salaryMax,
            unitText: salaryAggregation?.toUpperCase(),
          },
        }
      : null;

  const schema = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: jobTitle,
    description: `${main[0]?.content || ""}`,
    datePosted: new Date(createdAt).toLocaleDateString("en-CA"),
    validThrough:
      expires 
        ? `${new Date(expires).toLocaleDateString("en-CA")}T00:00` 
        : `${new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toLocaleDateString("en-CA")}T00:00`,
    employmentType: "CONTRACTOR",
    identifier: jobUid
      ? {
          "@type": "PropertyValue",
          name: company.name,
          value: jobUid || "",
        }
      : null,
    hiringOrganization: {
      "@type": "Organization",
      name: companyName,
      sameAs: companyUrl,
      logo: companyLogo ? `https://${process.env.GATSBY_PUBLIC_DOMAIN}${companyLogo}` : "",
    },
    applicantLocationRequirements: countries.map(({ name }) => ({
      "@type": "Country",
      name: name,
    })),
    jobLocationType: "TELECOMMUTE",
    baseSalary,
  };

  return (
    <SEO
      title={seoTitle || `${jobTitle} at ${companyName}`}
      description={seoDescriptiom}
      meta={
        author
          ? [
              {
                name: `article:author`,
                content: author,
              },
            ]
          : []
      }
      image={imageUrl}
      url={`/job/${slug}/`}
      schemaMarkup={schema}
    />
  );
};

export default JobSEO;
