export default function normalizeCaseStudies(caseStudies: any[]) {
  if (!caseStudies) return [];

  const validCaseStudies = caseStudies.filter(caseStudy => {
    return caseStudy.slug;
  });

  return validCaseStudies.map((item: any) => {
    const { featuredInfo, primaryQuestions, secondaryQuestions, seo } = item;

    return {
      ...item,
      featuredInfo: (featuredInfo || []).filter((item: any) => item.heading && item.value),
      primaryQuestions: (primaryQuestions || []).filter(
        (item: any) => item.question && item.answer
      ),
      secondaryQuestions: (secondaryQuestions || []).filter(
        (item: any) => item.question && item.answer
      ),
      seo: seo || {},
    };
  });
}
