import styled, { css } from 'styled-components';
import { media } from '../../common/MediaQueries';

export const JobStepsWrappe = styled.div`
  padding: 40px 0 40px 0;
  width: 100%;

  @media ${media.tablet} {
    padding: 80px 0 90px 0;
  }
`;

export const JobStepsImageWrapper = styled.div`
  width: 100%;
  margin: 32px auto 0 auto;
  max-width: 319px;

  @media ${media.phone} {
    max-width: 500px;
  }

  @media ${media.tablet} {
    width: 100%;
    margin: 79px auto 0 auto;
    max-width: 1000px;
  }
`;

export const JobStepsImagePadding = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 109.56%;
  overflow: hidden;

  @media ${media.tablet} {
    padding-bottom: 12.91%;
  }
`;

export const JobStepsImage = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

interface IHover {
  top: string;
  left: string;
}

export const StepsHover = styled.div<IHover>`
  display: none;

  @media ${media.tablet} {
    display: block;
    position: absolute;

    ${({ top, left }) => css`
      top: ${top};
      left: ${left};
    `}
  }
`;

export const StepsHoverContent = styled.div`
  background: transparent;
  width: 26px;
  height: 26px;
  flex-shrink: 0;
`;
