import React from "react";
import ICompanyDetails from "../../interfaces/ICompanyDetails";
import { Typo15, Typo24 } from "../Typography";
import {
  CompanyFeaturedBig,
  CompanyFeaturedItem,
  CompanyFeaturedSmall,
  CompanyFeturedWrapper,
  CompanyFeaturedItems,
  CompanyFeaturedHeading,
} from "./styles";

interface Props {
  company: ICompanyDetails;
}

const CompanyFeatured: React.FC<Props> = (props) => {
  const { company } = props;

  const { featuredInfo, featuredHeading } = company;

  if (featuredInfo.length === 0) return null;

  return (
    <CompanyFeturedWrapper>
      <CompanyFeaturedHeading>
        <Typo24 color="#6E95D7" fontWeight="600" textAlign="center" as="h2">
          {featuredHeading || "Company Info"}
        </Typo24>
      </CompanyFeaturedHeading>
      <CompanyFeaturedItems>
        {featuredInfo.map((item, index) => {
          const { bigText, smallText } = item;

          return (
            <CompanyFeaturedItem key={index}>
              <CompanyFeaturedBig>{bigText}</CompanyFeaturedBig>
              <CompanyFeaturedSmall>
                <Typo15 color="#6E95D7">{smallText}</Typo15>
              </CompanyFeaturedSmall>
            </CompanyFeaturedItem>
          );
        })}
      </CompanyFeaturedItems>
    </CompanyFeturedWrapper>
  );
};

export default CompanyFeatured;
