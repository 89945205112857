import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const DrawerArrow: SvgComponent = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 9.66681L6.9998 4.3333L0.999609 9.66681"
      stroke="#658AC8"
      strokeWidth="1.90482"
      strokeLinecap="round"
    />
  </svg>
);

export default DrawerArrow;
