import React from "react";
import ICompanyDetails from "../../interfaces/ICompanyDetails";
import CompanyCard from "../CompanyCard";
import CompanyInfo from "../CompanyInfo";
import CompanyLinks from "../CompanyLinks";
import { TypographyCompanyContent } from "../HTMLContent/style";
import JobContentApply from "../JobContentApply";
import { TechItems } from "../JobListItemNew/styles";
import JobSectionWrapper from "../JobSectionWrapper";
import { Heading19 } from "../Typography";
import {
  CompanyCheatWrapper,
  CompanyCheatContent,
  CompanyLinksMobileWrapper,
  CompanyCardsWrapper,
  CompanyCheatInner,
  CompanyCheatApplyWrapper,
  CompanyCheatHeadingWrapper,
} from "./styles";

interface Props {
  company: ICompanyDetails;
  applyUrl: string;
}

const CompanyCheat: React.FC<Props> = (props) => {
  const { company, applyUrl } = props;

  const { name, companyCheatSheet, finItems, teamItems, links } = company;

  if (!companyCheatSheet) return null;

  return (
    <CompanyCheatWrapper>
      <CompanyInfo
        type="cheat-sheet"
        renderHeading={() => (
          <CompanyCheatHeadingWrapper>
            <Heading19 color="#658AC8" as="h2">
              {`${name} Cheat Sheet`}
            </Heading19>
          </CompanyCheatHeadingWrapper>
        )}
      >
        <CompanyCheatInner>
          {companyCheatSheet && (
            <CompanyCheatContent>
              <TypographyCompanyContent
                dangerouslySetInnerHTML={{ __html: companyCheatSheet }}
              />
              <CompanyCheatApplyWrapper>
                <JobContentApply applyUrl={applyUrl} />
              </CompanyCheatApplyWrapper>
            </CompanyCheatContent>
          )}
          {(finItems.length > 0 || teamItems.length > 0) && (
            <CompanyCardsWrapper>
              {finItems.length > 0 && (
                <CompanyCard
                  first={true}
                  heading={"Financial Background"}
                  items={finItems}
                />
              )}
              {teamItems.length > 0 && (
                <CompanyCard heading={"Team stats"} items={teamItems} />
              )}
            </CompanyCardsWrapper>
          )}
          {links.length > 0 && (
            <CompanyLinksMobileWrapper>
              <CompanyLinks links={links} />
            </CompanyLinksMobileWrapper>
          )}
        </CompanyCheatInner>
      </CompanyInfo>
    </CompanyCheatWrapper>
  );
};

export default CompanyCheat;
