import React, { FocusEventHandler, useState, ReactElement, useEffect } from "react";
import { testEmail } from "../../common/utils";
import useUnloaded from "../../hooks/useUnloaded";
import fetchRetry from "../../lib/fetch-retry";

export interface RenderProps {
  value: string;
  state: IState;
  displayValid: boolean;
  onBlur: () => void;
  onFocus: () => void;
  onChange: (value: string) => void;
  onSubmit: () => void;
  valid: boolean;
}

interface Props {
  url: string
  renderContent: (props: RenderProps) => ReactElement | null
  payload: any
  onSubmitCallback?: Function
  onReceiveCallback?: Function
  initialEmail?: string | null
}

interface IState {
  loading?: boolean
  error?: boolean
  success?: boolean
  throttled?: boolean
  payload?: any
}

export interface ISendMail {
  value: string
  state: IState
  displayValid: boolean
  onBlur: FocusEventHandler<HTMLInputElement>
  onFocus: FocusEventHandler<HTMLInputElement>
  onChange: Function
  onSubmit: Function
  valid: boolean
}

const SendEmail: React.FC<Props> = (props) => {
  const {
    renderContent,
    payload,
    url,
    onSubmitCallback,
    onReceiveCallback,
    initialEmail,
  } = props;

  const [value, setValue] = useState("");

  const [valid, setValid] = useState(false);

  const [displayValid, setDisplayValid] = useState(true);

  const [state, setState] = useState<IState>({ loading: false });

  const unloaded = useUnloaded();

  useEffect(() => {
    if(!initialEmail) return;

    setValue(initialEmail);
    setValid(testEmail(initialEmail))
  }, [initialEmail])

  function onChange(value: string) {
    const valid = testEmail(value);

    setValue(value);
    setValid(valid);
  }

  function onBlur() {
    if (!valid && value !== "") {
      setDisplayValid(false);
    }
  }

  function onFocus() {
    setDisplayValid(true);
  }

  async function onSubmit() {
    try {
      if (!valid) return;

      onSubmitCallback && onSubmitCallback();

      setState({ loading: true });

      const data = await fetchRetry(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...payload,
          email: value,
        }),
      });

      if (data.throttled) {
        setState({ throttled: true });
        return;
      } else if (data.error) {
        setState({ error: true });
        return;
      }

      if (unloaded) return;

      onReceiveCallback &&
        onReceiveCallback({
          email: value,
          response: data,
        });

      setState({
        success: true,
        payload: data,
      });
    } catch (e) {
      console.log(e);

      setState({ error: true });
    }
  }

  return renderContent({
    value,
    state,
    displayValid,
    onBlur,
    onFocus,
    onChange,
    onSubmit,
    valid,
  });
};

export default SendEmail;
