import React from "react";
import Link from "gatsby-link";
import BackArrowWide from "../../assets/svg/BackArrowWide";
import IJobDetails from "../../interfaces/IJobDetails";
import JobSectionWrapper from "../JobSectionWrapper";
import TimeBadge from "../TimeBadge";
import { Typo23, Typo5 } from "../Typography";
import {
  JobHeaderWrapper,
  JobHeaderButton,
  JobHeaderArrowWrapper,
  JobHeaderBackTextWrapper,
  JobHeaderBadgeWrapper,
} from "./styles";

interface Props {
  job: IJobDetails;
}

const JobHeader: React.FC<Props> = (props) => {
  const { job } = props;

  const { timezoneGMTMin, timezoneGMTMax, timezoneCategory } = job;

  const fromGMT = timezoneCategory
    ? timezoneCategory.fromGMT
    : timezoneGMTMin || -12;
  const toGMT = timezoneCategory
    ? timezoneCategory.toGMT
    : timezoneGMTMax || 12;

  return (
    <JobSectionWrapper>
      <JobHeaderWrapper>
        <Link to="/jobs/">
          <JobHeaderButton>
            <JobHeaderArrowWrapper>
              <BackArrowWide />
            </JobHeaderArrowWrapper>
            <JobHeaderBackTextWrapper>
              <Typo23 color="#53637C" fontWeight="500">
                Back
              </Typo23>
            </JobHeaderBackTextWrapper>
          </JobHeaderButton>
        </Link>
        <JobHeaderBadgeWrapper>
          <TimeBadge fromGMT={fromGMT} toGMT={toGMT} />
        </JobHeaderBadgeWrapper>
      </JobHeaderWrapper>
    </JobSectionWrapper>
  );
};

export default JobHeader;
