import React from "react";
import ICompanyDetails from "../../interfaces/ICompanyDetails";
import StrapiImage from "../StrapiImage";
import { Typo16 } from "../Typography";
import {
  CompanyOneLinerImageWrapper,
  CompanyOneLinerText,
  CompanyOneLinerWrapper,
} from "./style";

interface Props {
  company: ICompanyDetails | null;
}

const CompanyOneLiner: React.FC<Props> = (props) => {
  const { company } = props;

  if (!company) return null;

  const { logo, oneLiner } = company;

  if (!logo || !oneLiner) return null;

  return (
    <CompanyOneLinerWrapper>
      <CompanyOneLinerImageWrapper>
        <StrapiImage image={logo} />
      </CompanyOneLinerImageWrapper>
      <CompanyOneLinerText>
        <p>{oneLiner}</p>
      </CompanyOneLinerText>
    </CompanyOneLinerWrapper>
  );
};

export default CompanyOneLiner;
