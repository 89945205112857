import React from "react";
import { Typography } from "./style";

interface Props {
  html: string;
}

const HTMLContent: React.FC<Props> = (props) => {
  const { html } = props;

  return <Typography dangerouslySetInnerHTML={{ __html: html }} />;
};

export default HTMLContent;
