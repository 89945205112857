import React, { createContext, Dispatch, SetStateAction } from "react";
import { SendPopup } from "../components/SendPopup";
import usePopup from "../hooks/usePopup";

interface ISendContext {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const SendContext = createContext<ISendContext>({
  setOpen: () => {},
});

interface Props {
  slug: string;
}

const SendContextProvider: React.FC<Props> = (props) => {
  const { children, slug } = props;

  const { open, setOpen } = usePopup();

  return (
    <SendContext.Provider value={{ setOpen }}>
      {children}
      {open && <SendPopup slug={slug} setOpen={setOpen} />}
    </SendContext.Provider>
  );
};

export default SendContextProvider;
