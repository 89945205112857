import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const Plus: SvgComponent = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 13H18" stroke="#658AC8" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M13 18L13 8"
      stroke="#658AC8"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Plus;
