import React, { useState } from "react";
import useUpdate from "./useUpdate";

const usePopup = () => {
  const [open, setOpen] = useState(false);

  function cleanup() {
    const scrollingElement = document.scrollingElement as HTMLElement;

    scrollingElement.style.removeProperty("overflow");
  }

  useUpdate(() => {
    if (!document.scrollingElement) return;

    const scrollingElement = document.scrollingElement as HTMLElement;

    if (open) {
      scrollingElement.style.overflow = "hidden";
      return cleanup;
    }

    scrollingElement.style.removeProperty("overflow");
  }, [open]);

  return { open, setOpen };
};

export default usePopup;
