import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const Check: SvgComponent = (props) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1668 7.55715V8.17048C15.166 9.6081 14.7005 11.0069 13.8397 12.1584C12.9789 13.3098 11.769 14.1521 10.3904 14.5597C9.01178 14.9674 7.53834 14.9184 6.18981 14.4202C4.84128 13.922 3.68993 13.0012 2.90747 11.7952C2.12501 10.5892 1.75336 9.16253 1.84795 7.72803C1.94254 6.29353 2.4983 4.92803 3.43235 3.8352C4.3664 2.74236 5.62869 1.98074 7.03096 1.66391C8.43322 1.34709 9.90034 1.49204 11.2135 2.07715"
      stroke="#0B264C"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1667 4.5L8.5 11.1733L6.5 9.17333"
      stroke="#0B264C"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Check;
