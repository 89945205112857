import React from "react";
import IJobDetails from "../../interfaces/IJobDetails";
import { Heading16 } from "../Typography";
import {
  JobCaseStudiesContainer,
  JobCaseStudiesHeading,
  JobCaseStudiesMaxWidth,
  JobCaseStudiesWrapper,
} from "./styles";
import CaseStudiesSlider from "../CaseStudiesSlider";
import ICompany from "../../interfaces/ICompany";

interface Props {
  heading: string;
  job: IJobDetails;
}

const JobCaseStudies: React.FC<Props> = (props) => {
  const { job, heading } = props;

  const { company } = job;

  const { name, caseStudies } = company;

  if (caseStudies.length === 0) return null;

  const first = caseStudies.slice(0, 3);

  const formatted = heading.replace("$company", name);

  return (
    <JobCaseStudiesWrapper>
      <JobCaseStudiesHeading>
        <Heading16 textAlign="center" as="h2">
          {formatted}
        </Heading16>
      </JobCaseStudiesHeading>
      <JobCaseStudiesMaxWidth>
        <JobCaseStudiesContainer>
          <CaseStudiesSlider items={first} />
        </JobCaseStudiesContainer>
      </JobCaseStudiesMaxWidth>
    </JobCaseStudiesWrapper>
  );
};

export default JobCaseStudies;
