import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const JobHeaderWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  width: 100%;
  min-height: 46px;

  @media ${media.tablet} {
    padding-top: 28px;
  }
`;

export const JobHeaderButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const JobHeaderArrowWrapper = styled.div`
  width: 21px;

  & svg {
    width: 100%;
  }
`;

export const JobHeaderBackTextWrapper = styled.div`
  margin-left: 8px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
`;

export const JobHeaderBadgeWrapper = styled.div`
  margin-left: auto;

  @media ${media.tablet} {
    display: none;
  }
`;
