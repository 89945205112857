import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const CompanyLinksStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 14px;

  @media ${media.tablet} {
    margin-top: 0;
    justify-content: flex-end;
  }
`;

export const CompanyLink = styled.a`
  display: flex;
  align-items: center;
  margin: 6px 14px 0 14px;
  letter-spacing: -0.04em;
  text-decoration: none;
  flex-shrink: 0;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.sky};

  &:hover {
    opacity: 0.85;
  }

  @media ${media.tablet} {
    margin-top: 7px;
    margin-left: 78px;
    margin-right: 0;
    letter-spacing: -0.01em;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const CompanyLinkIamgeWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 4px;

  @media ${media.tablet} {
    width: 19px;
    height: 19px;
    margin-right: 11px;
  }
`;
