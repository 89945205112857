import React from "react";
import { SvgComponent } from "../../definitions/Svg";

const MailClose: SvgComponent = (props) => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.92355 3.70955C4.54221 3.31005 3.90922 3.29533 3.50972 3.67667C3.11022 4.05801 3.0955 4.691 3.47684 5.0905L4.92355 3.70955ZM16.0768 18.2905C16.4582 18.69 17.0912 18.7047 17.4907 18.3234C17.8902 17.942 17.9049 17.309 17.5236 16.9095L16.0768 18.2905ZM3.47684 5.0905L16.0768 18.2905L17.5236 16.9095L4.92355 3.70955L3.47684 5.0905Z"
      fill="#658AC8"
    />
    <path
      d="M17.5232 5.0905C17.9045 4.691 17.8898 4.05801 17.4903 3.67667C17.0908 3.29533 16.4578 3.31005 16.0764 3.70955L17.5232 5.0905ZM3.47645 16.9095C3.09511 17.309 3.10983 17.942 3.50933 18.3234C3.90883 18.7047 4.54182 18.69 4.92316 18.2905L3.47645 16.9095ZM16.0764 3.70955L3.47645 16.9095L4.92316 18.2905L17.5232 5.0905L16.0764 3.70955Z"
      fill="#658AC8"
    />
  </svg>
);

export default MailClose;
