import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const CompanyOneLinerWrapper = styled.div`
  padding-top: 21px;
  display: flex;
  align-items: center;

  @media ${media.tablet} {
    padding-top: 10px;
  }
`;

export const CompanyOneLinerImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 66px;
  flex-shrink: 0;
  background: #f7f7f7;
  border-radius: 10px;
  overflow: hidden;

  & img {
    border-radius: 10px;
  }

  @media ${media.tablet} {
    display: none;
  }
`;

export const CompanyOneLinerText = styled.div`
  margin-left: 12px;
  letter-spacing: -0.01em;
  line-height: 22px;
  font-size: 14px;
  color: #0b264c;

  @media ${media.tablet} {
    margin: 0;
    font-size: 16px;
    line-height: 21px;
  }
`;
