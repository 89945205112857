import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

interface ICompanyCardWrapper {
  first?: boolean;
}

export const CompanyCardWrapper = styled.div<ICompanyCardWrapper>`
  ${({ first }) => (first ? 'margin-top: 0' : 'margin-top: 10px;')};

  background: #ffffff;
  border: 1px solid rgba(155, 192, 255, 0.38);
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 17px 19px 13px 19px;

  @media ${media.tablet} {
    box-shadow: none;
  }
`;

export const CompanyCardItems = styled.ul`
  padding-top: 11px;
`;

export const CompanyCardItem = styled.li`
  display: flex;
  align-items: center;
  padding: 6px 0;
`;

export const CompanyCardImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 34px;
  background: #f0f5ff;
  border-radius: 5px;
  flex-shrink: 0;

  @media ${media.tablet} {
    width: 34px;
  }
`;

export const CompanyCardImage = styled.div`
  width: 15px;
  height: 15px;
`;

export const ComapnyCardHeading = styled.div`
  margin-left: 11px;
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    margin-left: 10px;
  }
`;

export const CompanyCardValue = styled.div`
  margin-left: auto;
  padding-left: 20px;
  letter-spacing: -0.01em;
  text-align: right;
`;
