import React from "react";
import ILink from "../../interfaces/ILink";
import StrapiImage from "../StrapiImage";
import { Typo37 } from "../Typography";
import {
  CompanyLink,
  CompanyLinkIamgeWrapper,
  CompanyLinksStyled,
} from "./styles";

interface Props {
  links: ILink[];
}

const CompanyLinks = (props: Props) => {
  const { links } = props;

  if (links.length === 0) return null;

  return (
    <CompanyLinksStyled>
      {links.map((item, index) => {
        const { url, icon, label } = item;
        return (
          <CompanyLink href={url} target="_blank" key={url}>
            {icon && (
              <CompanyLinkIamgeWrapper>
                <StrapiImage image={icon} />
              </CompanyLinkIamgeWrapper>
            )}
            <Typo37 color="#658AC8" fontWeight="500">
              {label}
            </Typo37>
          </CompanyLink>
        );
      })}
    </CompanyLinksStyled>
  );
};

export default CompanyLinks;
