import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const CaseStudiesItemPadding = styled.div`
  padding: 0 5px;
  flex: 1 0 195px;

  @media ${media.tablet} {
    flex: 1 0 33.3333%;
    padding: 0 33px;
  }
`;

export const CaseStudiesItem = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 8.06983px 0px 25.1061px rgba(101, 138, 200, 0.08);
  border-radius: 9px;
  padding: 12px 12px 18px 12px;

  @media ${media.tablet} {
    padding: 24px 24px 26px 24px;
    border-radius: 10px;
  }
`;

export const CaseImageWrapper = styled.div`
  width: 100%;
  border-radius: 6px;
  overflow: hidden;

  @media ${media.tablet} {
    width: 80%;
    margin: auto;
  }

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  & img {
    border-radius: 6px;
  }
`;

export const CaseHeading = styled.div`
  margin-top: 12px;
  letter-spacing: -0.01em;
  color: #658ac8;
  font-weight: 500;

  @media ${media.desktop} {
    margin-top: 21px;
    letter-spacing: -0.002em;
    font-weight: 600;
  }
`;

export const CaseText = styled.div`
  margin-top: 3px;
  letter-spacing: -0.01em;
  font-variant: small-caps;
  text-transform: uppercase;

  @media ${media.desktop} {
    margin-top: 5px;
    letter-spacing: -0.03em;
  }
`;
