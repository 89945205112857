import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

interface JobMidWrapperProps {
  paddingBottom: boolean;
}

export const JobMidWrapper = styled.div<JobMidWrapperProps>`
  ${({ paddingBottom }) => (paddingBottom ? 'padding-bottom: 60px;' : '')}

  @media ${media.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const JobMidTestemonialWrappers = styled.div`
  margin: 25px auto 0 auto;
  width: 100%;
  overflow: hidden;

  @media ${media.tablet} {
    margin: 48px -5px 0 -5px;
  }

  @media ${media.desktop} {
    width: 100%;
    padding: 0 90px;
    margin: 48px auto 0 auto;
    max-width: 1546px;
  }
`;

export const JobMidStickyWrapper = styled.div`
  @media ${media.tablet} {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
`;

export const JobMidCompanyWrapper = styled.div`
  @media ${media.tablet} {
    margin-right: auto;
  }
`;

export const JobMidCompanySticky = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    width: 300px;
    margin-left: 22px;
    flex-shrink: 0;
    padding-top: 14px;

    @supports (position: sticky) {
      position: sticky;
      top: 84px;
    }
  }

  @media (min-width: 1200px) {
    width: 350px;
  }

  @media ${media.desktop} {
    width: 430px;
  }
`;

interface IJobMidCompanyCardWrapper {
  extraPaddingDesktop?: boolean;
}

export const JobMidCompanyCardWrapper = styled.div<IJobMidCompanyCardWrapper>`
  margin-top: 20px;

  @media ${media.tablet} {
    margin-top: 0;

    ${({ extraPaddingDesktop }) => extraPaddingDesktop && 'padding-top: 46px'}
  }
`;

export const JobMidCompanyLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding-left: 20px;
`;

export const JobMidCompanyCheatHeading = styled.div`
  @media ${media.tablet} {
    flex-shrink: 0;
  }
`;

export const JobMidCompanyCheatHeadingLinks = styled.div`
  display: none;

  @media ${media.tablet} {
    display: flex;
    align-items: flex-start;
    padding: 60px 0 24px 0;
  }
`;
