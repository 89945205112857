import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import IJobDetails from "../../interfaces/IJobDetails";
import { Typo16, Typo21 } from "../Typography";

interface Props {
  job: IJobDetails;
}

interface IWrapper {
  visaSponsorship: boolean;
}

const Padding = styled.div`
  @media ${media.desktop} {
    padding-top: 1px;
  }
`;

const Wrapper = styled.div<IWrapper>`
  padding: 8px 0 7px 0px;
  letter-spacing: -0.01em;
  text-align: center;
  ${({ visaSponsorship }) =>
    visaSponsorship
      ? "background: #E2FACF"
      : "background: rgba(255, 80, 112, 0.08);"};

  @media ${media.desktop} {
    padding-left: 24px;
    padding-right: 24px;
    text-align: left;
  }
`;

const JobVisa: React.FC<Props> = (props) => {
  const { job } = props;

  const { visaSponsorship } = job;

  return (
    <Padding>
      <Wrapper visaSponsorship={visaSponsorship}>
        <Typo21 color="#0B264C" fontWeight="500" lineHeight="22px">
          Visa Sponsorship: {visaSponsorship ? "Available" : "Not Available"}
        </Typo21>
      </Wrapper>
    </Padding>
  );
};

export default JobVisa;
