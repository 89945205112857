import React, { useContext } from "react";
import styled, { css } from "styled-components";
import decorateUrl from "../../common/decorateUrl";
import { media } from "../../common/MediaQueries";
import { SendContext } from "../../contexts/SendContext";
import { UtmContext } from "../../contexts/UtmContext";
import linkedinEvent from "../../lib/linkedin-event";
import ApplyButton from "../ApplyButton";
import { Typo14 } from "../Typography";

interface I {
  secondary?: boolean;
  noBorder?: boolean;
}

const Wrapper = styled.div<I>`
  display: none;

  @media ${media.tablet} {
    display: flex;
    align-items: center;

    ${({ secondary, noBorder }) => {
      if (noBorder) {
        return css`
          padding-top: 20px;
          padding-bottom: 20px;
        `;
      }

      if (secondary) {
        return css`
          padding-top: 32px;
          padding-bottom: 32px;
          border-top: 1px solid rgba(101, 138, 200, 0.2);
        `;
      }

      return css`
        padding: 20px 0;
        border-top: 1px solid rgba(101, 138, 200, 0.2);
        border-bottom: 1px solid rgba(101, 138, 200, 0.2);
      `;
    }}
  }
`;

const ButtonWrapper = styled.div`
  width: 250px;

  @media ${media.desktop} {
    width: 307px;
  }
`;

const SendWrapper = styled.button`
  display: block;
  margin-left: 24px;
  text-transform: uppercase;
  font-variant: small-caps;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }
`;

const SendLine = styled.span`
  display: block;
  margin-top: 3px;
  height: 2px;
  width: 100%;
  background: #658ac8;
`;

interface Props {
  applyUrl: string;
  secondary?: boolean;
  noBorder?: boolean;
}

const JobContentApply: React.FC<Props> = (props) => {
  const { applyUrl, secondary, noBorder } = props;

  const { setOpen: setPopupOpen } = useContext(SendContext);

  const { utms } = useContext(UtmContext);

  if (!applyUrl) return null;

  const decoratedUrl = decorateUrl(applyUrl, utms);

  return (
    <Wrapper secondary={secondary} noBorder={noBorder}>
      <ButtonWrapper>
        <ApplyButton
          as="a"
          url={decoratedUrl}
          onClick={() => { 
            linkedinEvent('toggl');
          }}
        />
      </ButtonWrapper>
      <SendWrapper>
        <Typo14
          fontWeight="600"
          color="#658AC8"
          onClick={() => setPopupOpen(true)}
        >
          Send Role to Email
        </Typo14>
        <SendLine />
      </SendWrapper>
    </Wrapper>
  );
};

export default JobContentApply;
