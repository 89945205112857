import React from "react";
import ICompanyDetails from "../../interfaces/ICompanyDetails";
import CompanyInfo from "../CompanyInfo";
import { TypographyCompanyDay } from "../HTMLContent/style";
import { Heading19, Typo21 } from "../Typography";
import {
  CompanyDayHeading,
  CompanyDaySubheading,
  CompanyDayTextWrapper,
  CompanyDayWrapper,
} from "./styles";

interface Props {
  company: ICompanyDetails;
}

const CompanyDay: React.FC<Props> = (props) => {
  const { company } = props;

  const { cultureHeading, cultureSubheading, cultureText } = company;

  if (!cultureHeading || !cultureText) return null;

  return (
    <CompanyDayWrapper>
      <CompanyInfo
        type="cheat-culture"
        renderHeading={() => (
          <CompanyDayHeading>
            <Heading19 color="#658AC8" as="h2">
              {cultureHeading}
            </Heading19>
          </CompanyDayHeading>
        )}
      >
        {cultureSubheading && (
          <CompanyDaySubheading>
            <Typo21 color="#0B264C" as="h3">
              {cultureSubheading}
            </Typo21>
          </CompanyDaySubheading>
        )}
        <CompanyDayTextWrapper>
          <TypographyCompanyDay
            dangerouslySetInnerHTML={{ __html: cultureText }}
          />
        </CompanyDayTextWrapper>
      </CompanyInfo>
    </CompanyDayWrapper>
  );
};

export default CompanyDay;
