import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const BottomBarWrapper = styled.div`
  display: none;

  @supports (position: sticky) {
    display: block;
    position: sticky;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    box-shadow: 0px 0px 23px rgb(0 0 0 / 8%);
  }

  @media ${media.tablet} {
    display: none;
  }
`;

export const BottomBarTop = styled.div`
  padding: 0 16px;
  max-height: 0;
  transition: max-height 200ms ease-out;
  overflow: hidden;
`;

interface IBottomBarInputWrapper {
  $loading: boolean;
}

export const BottomBarInputWrapper = styled.div<IBottomBarInputWrapper>`
  display: flex;
  width: 100%;
  padding-top: 6px;

  ${({ $loading }) => ($loading ? 'opacity: 0.4;' : '')}
`;

interface IValid {
  error: boolean;
}

export const BottomBarInputBorder = styled.div`
  flex-grow: 1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(101, 138, 200, 0.5);
  border-right: 0;
`;

export const BottomBarInput = styled.input<IValid>`
  width: 100%;
  height: 100%;
  padding: 8px 5px 8px 20px;
  border: none;
  font-size: 16px;
  line-height: 24px;

  ${({ error }) => (error ? 'color: rgba(255, 80, 112, .6);' : 'color: #0B264C;')}

  font-family: Montserrat, sans-serif;

  & ::placeholder {
    color: #697a90;
  }
`;

export const BottomBarSend = styled.button`
  width: 87px;
  flex-shrink: 0;
  padding: 10px 0;
  background: #658ac8;
  border-radius: 3px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.85;
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const BottomBarBottom = styled.div`
  display: flex;
  padding: 6px 16px;
`;

export const BottomBarSmallButton = styled.div`
  display: flex;
  width: 51px;
  height: 44px;
  justify-content: center;
  align-items: center;
  background: #f0f5ff;
  border-radius: 10px;
  flex-shrink: 0;
`;

export const BottomBarApplyWrapper = styled.div`
  margin-left: 6px;
  flex-grow: 1;
`;

export const BottomBarMessage = styled.div`
  height: 50px;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
