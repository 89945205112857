import React from "react";
import ICompanyInfo from "../../interfaces/ICompanyInfo";
import IStrapiImage from "../../interfaces/IStrapiImage";
import StrapiImage from "../StrapiImage";
import { Typo14, Typo16, Typo33, Typo39, Typo40 } from "../Typography";
import {
  ComapnyCardHeading,
  CompanyCardImage,
  CompanyCardImageWrapper,
  CompanyCardItem,
  CompanyCardItems,
  CompanyCardValue,
  CompanyCardWrapper,
} from "./style";

interface Props {
  heading: string;
  items: ICompanyInfo[];
  first?: boolean;
}

const CompanyCard: React.FC<Props> = (props) => {
  const { heading, items, first } = props;

  if (items.length === 0) return null;

  return (
    <CompanyCardWrapper first={first}>
      <Typo39
        fontWeight="600"
        color="#0B264C"
        textAlign="center"
        lineHeight="24px"
        as="h3"
      >
        {heading}
      </Typo39>
      <CompanyCardItems>
        {items.map((item) => {
          const { icon, heading, value } = item;

          return (
            <CompanyCardItem key={`${item.heading}${item.value}`}>
              <CompanyCardImageWrapper>
                <CompanyCardImage>
                  {icon && <StrapiImage image={icon} />}
                </CompanyCardImage>
              </CompanyCardImageWrapper>
              <ComapnyCardHeading>
                <Typo40 color="#658AC8" fontWeight="500">
                  {heading}
                </Typo40>
              </ComapnyCardHeading>
              <CompanyCardValue>
                <Typo40 color="#658AC8" fontWeight="500">
                  {value}
                </Typo40>
              </CompanyCardValue>
            </CompanyCardItem>
          );
        })}
      </CompanyCardItems>
    </CompanyCardWrapper>
  );
};

export default CompanyCard;
