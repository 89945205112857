import React from "react";
import { SvgComponent } from "../../definitions/Svg";

const Mail: SvgComponent = (props) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.83366 3.83331H19.167C20.2212 3.83331 21.0837 4.69581 21.0837 5.74998V17.25C21.0837 18.3041 20.2212 19.1666 19.167 19.1666H3.83366C2.77949 19.1666 1.91699 18.3041 1.91699 17.25V5.74998C1.91699 4.69581 2.77949 3.83331 3.83366 3.83331Z"
      stroke="#658AC8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0837 5.75L11.5003 12.4583L1.91699 5.75"
      stroke="#658AC8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Mail;
